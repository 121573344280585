import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Contact() {
  return (
    <>
    <Header/>
    <div className="section sct-form">
<div className="container">
<div className="form-wrapper">
<form className="form form--contact" id="contact-form" action="/contact" method="get">
<input type="hidden" name="action" value="process_my_form"/>
<div className="form-row">
<label for="name" className="form-label"><font style={{verticalalign: "inherit"}}><font style={{verticalalign: "inherit"}}>Etternavn</font></font></label>
<input className="input" type="text" placeholder="Etternavn" name="name" required=""/>
</div>
<div className="form-row">
<label for="email" className="form-label"><font style={{verticalalign: "inherit"}}><font style={{verticalalign: "inherit"}}>Epostadresse</font></font></label>
<input required="" className="input" type="email" placeholder="Epostadresse" name="email"/>
</div>
<div className="form-row">
<label for="message" className="form-label"><font style={{verticalalign: "inherit"}}><font style={{verticalalign: "inherit"}}>Nyheter</font></font></label>
<textarea required="" className="textarea" type="text" placeholder="Nyheter" name="message"></textarea>
</div>
<div className="form-row">
<button type="submit" className="button">
<span><font style={{verticalalign: "inherit"}}><font style={{verticalalign: "inherit"}}>Sende</font></font></span>
</button>
</div>
</form>
</div>
</div>
</div>
<Footer/>
    </>
  )
}

export default Contact