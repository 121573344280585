import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <div className="hero">
      <div className="container">
        <div className="hero-grid">
          <div className="hero-col-1">
          <div className="hero-logo">
        <Link to="/">
          <img src="/logo.png" alt="site logo" />
        </Link>
        <ul id="menu-bottom" className="footer-menu">
          <li id="menu-item-29" className="menu-item">
            <Link to="/about" style={{ textDecoration: 'none' }}>Om oss</Link>
          </li>
          <li id="menu-item-30" className="menu-item">
            <Link to="/contact" style={{ textDecoration: 'none' }}>Kontakt oss</Link>
          </li>
        </ul>
      </div>
            <div className="hero-content content">
            <h1>
<font style={{verticalAlign: "inherit"}} ><font style={{verticalAlign: "inherit"}}>De mest populære hotellene med kasino i Norge i 2024</font></font>
</h1>
              <p>Bli kjent med våre fremste destinasjoner for minneverdige reiseopplevelser.</p>
            </div>
            <div className="casinos-nav-wrapper">
              <div className="casinos-nav">
                <a className="casino-nav-item" href= "#hotel-one">
                  <img src="/marker.png" alt="location marker" />
                  1. Storfjord Hotel
                </a>
                <a className="casino-nav-item" href= "#hotel-two">
                  <img src="/marker.png" alt="location marker" />
                  2. Dalen Hotel
                </a>
                <a className="casino-nav-item" href= "#hotel-three">
                  <img src="/marker.png" alt="location marker" />
                  3. Eilert Smith Hotel
                </a>
                <a className="casino-nav-item" href= "#hotel-four">
                  <img src="/marker.png" alt="location marker" />
                  4. Hotel Ullensvang
                </a>
                <a className="casino-nav-item" href= "#hotel-five">
                  <img src="/marker.png" alt="location marker" />
                  5. Sorrisniva
                </a>
                <a className="casino-nav-item" href= "#hotel-six">
                  <img src="/marker.png" alt="location marker" />
                  6. Grande Fjord Hotel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
