import React from 'react';

function Hotel() {
  return (
    <>
      <div id="hotels" className="section casinos-list">
        <div className="container">
          <div className="casinos-list-grid">
            <div id="hotel-six" className="casino-hotel-card">
              <div className="casino-hotel-image">
                <div className="casino-hotel-image-wrapper" style={{ width: '200px' }}>
                  <img src="/16.jpg" alt="Red Rock Casino" />
                </div>
              </div>
              <div className="casino-hotel-excerpt">
                <div className="content">
                  <h4>Eilert Smith Hotel</h4>
                  <div className="stars-rating">
                    <img src="/stars.png" alt="5 stars" />
                  </div>
                  <div className="dashes">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="excerpt-content">
                    <p>Eilert Smith Hotel er en perle midt i Stavangers historiske trehusmiljø og moderne arkitektur. Det omgjorte funksjonalistiske pakkhuset fra 1937 tilbyr nå en elegant boutiquehotellopplevelse med respekt for sin historiske arv. Hotellet har også et sofistikert kasino hvor gjestene kan oppleve spennende spill i en stilig atmosfære.</p>
                  </div>
                  <a className="button button--alt" href="https://www.eilertsmith.no/">
                    Utforsk nå
                  </a>
                </div>
              </div>
            </div>

            <div id="hotel-five" className="casino-hotel-card">
              <div className="casino-hotel-image">
                <div className="casino-hotel-image-wrapper" style={{ width: '200px' }}>
                  <img src="/15.jpg" alt="The D Las Vegas" />
                </div>
              </div>
              <div className="casino-hotel-excerpt">
                <div className="content">
                  <h4>Grande Fjord Hotel</h4>
                  <div className="stars-rating">
                    <img src="/stars.png" alt="5 stars" />
                  </div>
                  <div className="dashes">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="excerpt-content">
                    <p>Grande Fjord Hotel, et elegant 4-stjerners boutiquehotell, ligger bare 100 meter fra den verdenskjente Geirangerfjorden. Med sin fredelige beliggenhet nær Geiranger sentrum tilbyr hotellet ikke bare ro og avslapning, men også et moderne kasino hvor gjestene kan nyte en spennende spilleopplevelse med en panoramautsikt over fjorden.</p>
                  </div>
                  <a className="button button--alt" href="https://www.grandefjordhotel.com/">
                    Utforsk nå
                  </a>
                </div>
              </div>
            </div>

            <div id="hotel-one" className="casino-hotel-card">
              <div className="casino-hotel-image">
                <div className="casino-hotel-image-wrapper" style={{ width: '200px' }}>
                  <img src="/11.jpg" alt="The Cosmopolitan" />
                </div>
              </div>
              <div className="casino-hotel-excerpt">
                <div className="content">
                  <h4>Storfjord Hotel</h4>
                  <div className="stars-rating">
                    <img src="/stars.png" alt="5 stars" />
                  </div>
                  <div className="dashes">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="excerpt-content">
                    <p>Storfjord Hotel er et eksklusivt boutiquehotell som ligger i Glomset, på en åsside med fantastisk utsikt over Storfjorden og de majestetiske Sunnmørsalpene. Hotellet tilbyr ikke bare en unik naturopplevelse, men har også et elegant kasino hvor gjester kan prøve lykken etter en dag med utforsking av det naturskjønne området.</p>
                  </div>
                  <a className="button button--alt" href="https://storfjordhotel.com/no/the-hotel">
                    Utforsk nå
                  </a>
                </div>
              </div>
            </div>

            <div id="hotel-two" className="casino-hotel-card">
              <div className="casino-hotel-image">
                <div className="casino-hotel-image-wrapper" style={{ width: '200px' }}>
                  <img src="/12.jpg" alt="Golden Nugget" />
                </div>
              </div>
              <div className="casino-hotel-excerpt">
                <div className="content">
                  <h4>Dalen Hotel</h4>
                  <div className="stars-rating">
                    <img src="/stars.png" alt="5 stars" />
                  </div>
                  <div className="dashes">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="excerpt-content">
                    <p>Dalen Hotel ligger ved enden av Telemarkskanalen, omgitt av imponerende fjell og daler. Dette historiske eventyrhotellet fra 1894 imponerer med sin arkitektur og romantiske omgivelser som minner om eventyrene fra gamle dager. Hotellet har også et sjarmerende kasino som gir gjestene en unik mulighet til å nyte historisk sjarm kombinert med spennende spillaktiviteter.</p>
                  </div>
                  <a className="button button--alt" href="https://www.dalenhotel.no/no">
                    Utforsk nå
                  </a>
                </div>
              </div>
            </div>

            <div id="hotel-four" className="casino-hotel-card">
              <div className="casino-hotel-image">
                <div className="casino-hotel-image-wrapper" style={{ width: '200px' }}>
                  <img src="/14.jpg" alt="Wynn Las Vegas" />
                </div>
              </div>
              <div className="casino-hotel-excerpt">
                <div className="content">
                  <h4>Sorrisniva</h4>
                  <div className="stars-rating">
                    <img src="/stars.png" alt="5 stars" />
                  </div>
                  <div className="dashes">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="excerpt-content">
                    <p>Sorrisniva er et helårs feriested som tilbyr gjestene førsteklasses innkvartering i den moderne Arctic Wilderness Lodge og verdens nordligste iglohotell. I tillegg til sin verdensberømte mat og varierte villmarksaktiviteter, tilbyr Sorrisniva også et spennende kasino som perfekt utfyller den arktiske opplevelsen.</p>
                  </div>
                  <a className="button button--alt" href="https://www.sorrisniva.no/">
                    Utforsk nå
                  </a>
                </div>
              </div>
            </div>

            <div id="hotel-three" className="casino-hotel-card">
              <div className="casino-hotel-image">
                <div className="casino-hotel-image-wrapper" style={{ width: '200px' }}>
                  <img src="/13.jpg" alt="The Venetian" />
                </div>
              </div>
              <div className="casino-hotel-excerpt">
                <div className="content">
                  <h4>Hotel Ullensvang</h4>
                  <div className="stars-rating">
                    <img src="/stars.png" alt="5 stars" />
                  </div>
                  <div className="dashes">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="excerpt-content">
                    <p>Hotel Ullensvang ligger ved bredden av Hardangerfjorden og tilbyr en idyllisk opplevelse midt i spektakulær natur, inkludert utsikt til den majestetiske Folgefonna-breen. Etter å ha utforsket områdets naturskjønnhet kan gjestene slappe av på hotellets eksklusive kasino som tilbyr et variert utvalg av spill og underholdning.</p>
                  </div>
                  <a className="button button--alt" href="https://www.hotelullensvang.no/no/">
                    Utforsk nå
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Hotel;
