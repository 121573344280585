
import logo from './logo.svg';
import './App.css';

import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cookie from './pages/Cookie';

function App() {
  return (
    <div className="App">
  <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/termsandconditions" element={<Terms />} />
        <Route path="/cookie" element={<Cookie />} />
      </Routes>
    </div>
  );
}

export default App;

