import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Regular from '../components/Regular'
import Hotel from '../components/Hotel'
import Footer from '../components/Footer'
import axios from 'axios';
import Loader from '../components/Loader'
import NotFoundComponent from '../components/NotFoundComponent'
function Home() {

const [data, setData] = useState(null);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(false);

const [trackingData, setTrackingData] = useState({
  gclid: null,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_term: null,
});

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const gclid = queryParams.get('gclid');
    const utm_source = queryParams.get('utm_source');
    const utm_medium = queryParams.get('utm_medium');
    const utm_campaign = queryParams.get('utm_campaign');
    const utm_term = queryParams.get('utm_term');

    setTrackingData({
      gclid: gclid,
      utmSource: utm_source,
      utmMedium: utm_medium,
      utmCampaign: utm_campaign,
      utmTerm: utm_term
    });



  if(gclid){
    axios.get('https://api.norgestay.com/api/Hotels') 
    .then(response => {
      setData(response.data);
      setLoading(false); 
      console.log(response.data.isSuccess)
      setError(response.data.isSuccess==false);
    })
    .catch(error => {
      console.error('API çağrısı sırasında bir hata oluştu:', error);
      setLoading(false); 
    });
  }else{
    setLoading(false)
  }
   
  }, []);

  if (loading) return <Loader />;

  if (error) return <NotFoundComponent trackingData={trackingData} />;

  return (
    <>
      <Header />
      <Regular content={data} />
      <Hotel content={data} />
      <Footer />
    </>
  );
}

export default Home