import React from 'react';
import Header from '../components/Header';
import Aboutt from '../components/Aboutt';
import Footer from '../components/Footer';
import Regular from '../components/Regular';

function About() {
  return (
    <>
     <Header/>
     <Regular/>
     <Footer/>
    </>
  );
}

export default About;
