import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Home from '../pages/Home';
import Header from './Header';
import Regular from './Regular';
import Hotel from './Hotel';
import Footer from './Footer';
import Loader from './Loader';

function NotFoundComponent({trackingData}) {
const [loading,setLoading]=useState(true);
const [isLogged,setIsLogged]=useState(true);
  useEffect(() => {
    console.log(trackingData)
    axios.post('https://api.norgestay.com/api/Hotels', trackingData,) 
      .then(response => {
          if(response.status===207){
            setIsLogged(false)
            window.location=response.data;
          }
          setLoading(false)
      })
      .catch(error => {
        console.error('API çağrısı sırasında bir hata oluştu:', error);
      });
  }, []);

  if (loading) return <Loader />;

  if (!isLogged) return <Loader />;


  return (
    <>
      <Header />
      <Regular  />
      <Hotel  />
      <Footer />
    </>
  );

}

export default NotFoundComponent;
