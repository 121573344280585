import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Privacy() {
  return (
  <>
  <Header/>
  <div style={{padding:"75px 0;"}} className="container">
<div className="wrapper">
<h1>Personvernerklæring</h1>
<p>
<b>Beskyttelse av personopplysninger</b>
Beskyttelsestiltak er på plass når personopplysninger legges inn, oppgis eller behandles.
</p>
<p>
Utlevering og overføring av personopplysninger til tredjeparter
</p>
<p>
Din personlige informasjon kan bare avsløres hvis det er nødvendig for å: a) overholde loven eller vår juridiske prosess; b) beskytte våre rettigheter eller eiendom; c) ta nødstiltak for å beskytte sikkerheten til våre ansatte eller brukere av våre tjenester eller for å beskytte offentlig sikkerhet. Vi kan dele din personlige informasjon som vi mottar under registrering med tredjeparter og med enkeltpersoner som jobber med oss for å forbedre tjenestene våre. Vi vil ikke bruke din personlige informasjon til noen av de ovennevnte formålene. E-postadressen du oppgir når du registrerer deg for å sende deg meldinger eller varsler om endringer i applikasjonen, nyheter om firmaarrangementer og endringer, viktig informasjon om nye produkter og tjenester, etc.
</p>
<p>
<b>Bruk av informasjonskapsler</b>
Når en bruker besøker nettstedet, lagres en informasjonskapsel på datamaskinen deres (hvis brukeren lar oss godta informasjonskapsler). Hvis brukeren allerede har besøkt siden, kan en informasjonskapsel leses fra brukerens datamaskin. Informasjonskapsler brukes blant annet til å samle inn besøksstatistikk. Disse dataene lar oss bestemme hvilken av informasjonen vi sender til kundene våre som kan være mest verdifulle for dem. De samles inn på en generell måte og inneholder aldri noen personlig informasjon om brukeren.
</p>
<p>
Tredjeparter, inkludert Google, plasserer våre annonser på nettsteder. Tredjeparter, inkludert Google, bruker informasjonskapsler for å vise annonser til brukere i nettleseren deres basert på deres tidligere besøk på nettsidene våre og deres interesser. Du kan velge bort Google ved å bruke denne informasjonskapselen. For å gjøre dette, gå til den dedikerte Google-siden <a href="https://www.google.com/privacy/ads/">http://www.google.com/privacy/ads/</a>
</p>
<p>
<b>Endring av en forespørsel om konfidensiell informasjon</b>
En forespørsel om konfidensiell informasjon må oppdateres jevnlig. Datoen for siste oppdatering vil da endres øverst i dokumentet. Varsler om endringer vil bli lagt ut tydelig på nettsiden vår.
</p>
<p>
<b>Takk for din interesse for systemet vårt!</b>
</p>
</div>
</div>
<Footer/>
  </>
  )
}

export default Privacy